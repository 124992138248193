import React from 'react'
import Logo from './Logo'

const Footer = ({ userData }) => {
    return (
        <div style={{ padding: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div>{userData?.account_name}</div>
            <div>
                <Logo colour={'#494949'} width={120} />
            </div>
            <div>–</div>
            <div><a href="https://projecu.com">projecu.com</a></div>
        </div>
    )
}

export default Footer