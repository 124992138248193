import React, { useEffect, useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'yet-another-react-lightbox'; // Ensure Lightbox is imported properly
import styles from '../../styles/MainStyles.module.css'; // Import css modules stylesheet as styles
import "yet-another-react-lightbox/styles.css";

const Images = ({ content }) => {
  const [imageQty, setImageQty] = useState(0);
  const [images, setImages] = useState([]); // Initialize as an empty array
  const [index, setIndex] = useState(-1);

  // Update image quantity when content changes
  useEffect(() => {
    if (content) {
      setImageQty(content.length);
    }
  }, [content]);

  // Map content to images array when content changes
  useEffect(() => {
    if (content) {
      const getImages = content.map(image => ({
        src: image.downloadURL,
        width: image.width,
        height: image.height
      }));
      setImages(getImages);
    }
  }, [content]);

  // Handle image click and set the Lightbox index
  const handleClick = (imageIndex) => {
    setIndex(imageIndex);
  };

  // Gallery for multiple images
  if (imageQty > 1) {
    return (
      <div className={styles.widgetCard}>
        <div>Gallery</div>
        <div className={styles.gallery}>
          {images.map((image, imgIndex) => (
            <img
              key={imgIndex}
              className={styles.gallery__item}
              src={image.src}
              alt={`image-${imgIndex}`}
              onClick={() => handleClick(imgIndex)} // Pass the image index
            />
          ))}
        </div>
        <Lightbox
          slides={images} // Use images directly
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)} // Close Lightbox
        />
      </div>
    );
  }

  // Single image display
  return (
    <div>
      {content && content[0] && (
        <img
          src={content[0].downloadURL}
          alt="Single Image"
          style={{ width: '100%', height: 'auto', borderRadius: 6 }}
        />
      )}
    </div>
  );
};

export default Images;
