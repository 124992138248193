import React from 'react'

const Logo = ({ colour, width }) => {
    return (
        <div style={{ width: width, height: "auto" }}>
            <svg viewBox="0 0 4125 1097" fill={colour} xmlns="http://www.w3.org/2000/svg">
                <path d="M1528.06 784.276C1528.06 822.569 1508.05 841.715 1468.05 841.715C1448.62 841.715 1433.18 836.857 1421.75 827.141C1410.32 817.425 1404.61 803.137 1404.61 784.276V418.214C1404.61 354.203 1421.47 306.195 1455.19 274.189C1488.91 241.612 1545.49 225.324 1624.93 225.324C1683.8 225.324 1729.81 241.041 1762.95 272.475C1796.68 303.908 1813.54 354.489 1813.54 424.215C1813.54 484.797 1797.53 531.09 1765.53 563.096C1734.09 595.101 1689.51 611.104 1631.79 611.104C1599.21 611.104 1575.49 613.676 1560.63 618.819C1546.35 623.392 1537.2 632.25 1533.2 645.395C1529.77 657.969 1528.06 676.544 1528.06 701.119V784.276ZM1520.34 536.52C1527.2 530.804 1533.77 525.946 1540.06 521.946C1546.35 517.373 1554.63 513.944 1564.92 511.658C1575.78 508.801 1590.07 507.372 1607.79 507.372C1632.36 507.372 1651.79 505.371 1666.08 501.371C1680.37 496.799 1690.37 488.511 1696.09 476.509C1702.37 464.507 1705.52 447.076 1705.52 424.215C1705.52 393.924 1701.8 371.92 1694.37 358.203C1687.51 343.915 1677.51 334.771 1664.37 330.77C1651.79 326.198 1636.36 323.912 1618.07 323.912C1584.92 323.912 1561.49 329.913 1547.78 341.915C1534.63 353.917 1528.06 374.778 1528.06 404.497C1528.06 425.644 1527.49 448.79 1526.34 473.938C1525.2 499.085 1523.2 519.945 1520.34 536.52Z" fill="#494949" />
                <path d="M1926.67 841.715C1885.52 841.715 1864.95 822.283 1864.95 783.419V458.506C1864.95 419.071 1885.52 399.353 1926.67 399.353C1947.25 399.353 1962.1 403.926 1971.25 413.07C1980.39 421.643 1984.97 435.931 1984.97 455.934C1995.25 437.074 2009.83 423.072 2028.69 413.927C2048.12 404.211 2070.69 399.353 2096.41 399.353C2145.56 399.353 2170.14 420.786 2170.14 463.65C2170.14 479.653 2164.14 491.655 2152.14 499.656C2140.14 507.658 2120.42 511.658 2092.98 511.658C2066.12 511.658 2044.98 514.23 2029.55 519.374C2014.11 523.946 2003.25 532.805 1996.97 545.95C1991.25 558.523 1988.4 576.812 1988.4 600.816V783.419C1988.4 822.283 1967.82 841.715 1926.67 841.715Z" fill="#494949" />
                <path d="M2373.06 841.715C2311.9 841.715 2263.61 824.283 2228.18 789.42C2192.74 754.557 2175.02 698.262 2175.02 620.534C2175.02 542.235 2192.74 485.94 2228.18 451.648C2263.61 416.785 2311.9 399.353 2373.06 399.353C2434.21 399.353 2482.5 416.785 2517.94 451.648C2553.37 485.94 2571.09 542.235 2571.09 620.534C2571.09 698.262 2553.37 754.557 2517.94 789.42C2482.5 824.283 2434.21 841.715 2373.06 841.715ZM2373.06 738.84C2389.06 738.84 2402.49 735.697 2413.35 729.41C2424.21 722.551 2432.5 710.549 2438.21 693.404C2444.5 676.258 2447.64 651.968 2447.64 620.534C2447.64 589.1 2444.5 564.81 2438.21 547.664C2432.5 530.519 2424.21 518.802 2413.35 512.516C2402.49 505.657 2389.06 502.228 2373.06 502.228C2357.63 502.228 2344.19 505.657 2332.76 512.516C2321.91 518.802 2313.33 530.519 2307.05 547.664C2301.33 564.81 2298.47 589.1 2298.47 620.534C2298.47 651.968 2301.33 676.258 2307.05 693.404C2313.33 710.549 2321.91 722.551 2332.76 729.41C2344.19 735.697 2357.63 738.84 2373.06 738.84Z" fill="#494949" />
                <path d="M2711.92 325.626C2690.77 325.626 2673.06 318.768 2658.77 305.052C2644.48 291.335 2637.34 274.475 2637.34 254.471C2637.34 233.325 2644.48 215.893 2658.77 202.177C2673.06 188.46 2690.77 181.602 2711.92 181.602C2733.64 181.602 2751.36 188.46 2765.07 202.177C2779.36 215.893 2786.5 233.325 2786.5 254.471C2786.5 274.475 2779.36 291.335 2765.07 305.052C2751.36 318.768 2733.64 325.626 2711.92 325.626ZM2712.78 397.639C2735.07 397.639 2750.78 404.211 2759.93 417.356C2769.64 429.93 2774.5 445.933 2774.5 465.365V810.852C2774.5 876.578 2760.21 929.73 2731.64 970.308C2703.63 1010.89 2659.91 1031.46 2600.47 1032.03H2591.04C2544.75 1030.89 2511.03 1024.03 2489.88 1011.46C2468.74 999.456 2458.16 980.596 2458.16 954.877C2458.16 936.016 2463.31 923.443 2473.59 917.156C2483.31 911.441 2497.03 908.583 2514.74 908.583C2530.18 908.583 2543.32 909.726 2554.18 912.012C2565.04 914.87 2578.47 916.87 2594.47 918.013H2599.62C2622.48 918.013 2637.34 909.155 2644.19 891.437C2651.05 874.292 2654.48 853.145 2654.48 827.998V465.365C2654.48 441.932 2659.34 424.786 2669.06 413.927C2679.34 403.068 2693.92 397.639 2712.78 397.639Z" fill="#494949" />
                <path d="M3080.23 841.715C3023.08 841.715 2978.22 832.284 2945.64 813.424C2913.63 794.564 2890.77 768.559 2877.06 735.411C2863.91 702.262 2857.34 664.256 2857.34 621.391C2857.34 542.521 2874.77 485.94 2909.63 451.648C2944.5 416.785 2991.36 399.353 3050.23 399.353C3085.66 399.353 3117.1 405.354 3144.53 417.356C3171.96 429.358 3193.4 446.504 3208.83 468.794C3224.26 490.512 3231.97 517.088 3231.97 548.522C3231.97 639.966 3166.82 685.688 3036.51 685.688H2986.79C2991.36 703.405 2998.79 717.408 3009.08 727.695C3019.94 737.411 3038.8 742.269 3065.66 742.269C3080.52 742.269 3092.52 741.412 3101.67 739.697C3110.81 737.983 3119.38 736.268 3127.38 734.553C3135.96 732.839 3145.67 731.982 3156.53 731.982C3171.96 731.982 3184.25 734.839 3193.4 740.555C3202.54 745.698 3207.11 756.557 3207.11 773.131C3207.11 796.564 3196.82 813.996 3176.25 825.426C3156.25 836.285 3124.24 841.715 3080.23 841.715ZM2980.79 622.249C2985.36 615.962 2990.5 611.961 2996.22 610.246C3001.93 607.96 3010.79 606.817 3022.79 606.817C3056.51 606.817 3079.66 603.674 3092.24 597.387C3105.38 590.529 3111.95 578.527 3111.95 561.381C3111.95 539.663 3105.95 523.089 3093.95 511.658C3081.95 499.656 3066.8 493.655 3048.51 493.655C3027.37 493.655 3010.79 502.514 2998.79 520.231C2986.79 537.948 2980.79 571.954 2980.79 622.249Z" fill="#494949" />
                <path d="M3490.02 841.715C3420.29 841.715 3368.57 821.711 3334.85 781.704C3301.13 741.126 3284.27 687.688 3284.27 621.391C3284.27 555.094 3301.13 501.657 3334.85 461.078C3368.57 419.928 3420.29 399.353 3490.02 399.353C3534.03 399.353 3566.03 403.926 3586.03 413.07C3606.61 422.214 3616.9 438.503 3616.9 461.935C3616.9 478.51 3612.32 490.512 3603.18 497.942C3594.04 505.371 3581.75 509.086 3566.32 509.086C3555.46 509.086 3546.88 508.229 3540.6 506.514C3534.31 504.8 3527.45 503.371 3520.02 502.228C3513.16 500.513 3502.31 499.656 3487.45 499.656C3467.44 499.656 3451.44 505.657 3439.44 517.659C3428.01 529.661 3419.72 545.093 3414.58 563.953C3410 582.242 3407.72 601.388 3407.72 621.391C3407.72 640.823 3409.43 659.969 3412.86 678.83C3416.86 697.118 3424.58 712.264 3436.01 724.266C3447.44 735.697 3464.87 741.412 3488.3 741.412C3510.02 741.412 3526.02 739.983 3536.31 737.125C3546.6 733.696 3556.6 731.982 3566.32 731.982C3581.75 731.982 3594.04 734.839 3603.18 740.555C3612.32 745.698 3616.9 756.557 3616.9 773.131C3616.9 796.564 3606.61 813.996 3586.03 825.426C3566.03 836.285 3534.03 841.715 3490.02 841.715Z" fill="#494949" />
                <path d="M3898.58 841.715C3829.42 841.715 3776.56 824.569 3739.98 790.277C3703.97 755.414 3685.97 703.12 3685.97 633.393V292.192C3685.97 247.613 3706.54 225.324 3747.69 225.324C3788.84 225.324 3809.42 247.613 3809.42 292.192V633.393C3809.42 669.399 3817.13 695.69 3832.56 712.264C3848.57 728.838 3870.57 737.125 3898.58 737.125C3967.16 737.125 4001.45 702.548 4001.45 633.393V292.192C4001.45 247.613 4022.03 225.324 4063.18 225.324C4104.33 225.324 4124.9 247.613 4124.9 292.192V633.393C4124.9 702.548 4105.75 754.557 4067.46 789.42C4029.17 824.283 3972.87 841.715 3898.58 841.715Z" fill="#494949" />
                <path d="M599.556 253.869H432.111C432.111 253.869 378.096 253.869 378.096 321.387C378.096 388.905 432.111 388.905 432.111 388.905H599.556C599.556 388.905 653.571 388.905 653.571 321.387C653.571 253.869 599.556 253.869 599.556 253.869Z" fill="#494949" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M842.621 599.567L777.804 534.749C777.804 534.749 760.015 515.336 745.395 507.742C732.318 500.949 707.585 496.939 707.585 496.939H545.541C545.541 496.939 502.324 496.935 464.519 534.749C426.714 572.563 426.708 615.771 426.708 615.771V988.472C426.708 988.472 429.302 1023.47 464.513 1058.69C502.324 1096.5 534.738 1096.5 534.738 1096.5H983.059C983.059 1096.5 1026.27 1096.5 1064.08 1058.69C1091.09 1031.67 1091.09 988.472 1091.09 988.472V712.997C1091.09 712.997 1088.41 667.107 1053.27 631.971C1018.14 596.835 977.658 599.567 977.658 599.567H842.621ZM961.457 961.462V740.002H783.208L675.179 631.973H561.748V961.462H961.457Z" fill="#494949" />
                <path d="M216.059 1031.68H270.073C270.073 1031.68 318.686 1031.68 318.686 964.161C318.686 896.643 270.073 896.643 270.073 896.643H216.059C156.642 896.643 135.037 864.234 135.037 831.825V178.248C135.037 156.642 162.044 135.037 183.65 135.037H826.424C873.957 135.037 896.643 167.445 896.643 194.453V448.321C896.643 448.321 896.643 496.935 961.46 496.935C1026.28 496.935 1026.28 448.321 1026.28 448.321V183.65C1026.28 54.0146 902.044 0 826.424 0H183.65C75.6205 0 0 86.4234 0 172.847V848.03C0 945.256 97.2263 1031.68 216.059 1031.68Z" fill="#494949" />
            </svg>
        </div>

    )
}

export default Logo