import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import { FIRESTORE_DB } from '../firebase';
import { Helmet } from 'react-helmet';
import ProjectInfo from './components/ProjectInfo';
import Update from './components/Update';
import Footer from './components/Footer';

const ProjectPage = () => {

    const { pid } = useParams();

    const [updates, setUpdates] = useState(null);
    const [project, setProject] = useState(null);
    const [userData, setUserData] = useState(null);
    const [pageTitle, setPageTitle] = useState('');

    useEffect(() => {
        //load data from firebase
        if (pid) {
            // const userRef = doc(FIRESTORE_DB, "users", uid)
            const projectRef = doc(FIRESTORE_DB, "projects", pid)
            const updatesRef = collection(FIRESTORE_DB, "projects", pid, "updates")
            const getUpdates = async () => {
                const querySnapshot = await getDocs(query(updatesRef, orderBy("date", "desc")));
                setUpdates([]);
                querySnapshot.forEach((doc) => {
                    setUpdates((prev) => [...prev, doc.data()])
                    console.log(doc.id, " => ", doc.data());
                });
            }
            const getProject = async () => {
                const querySnapshot = await getDoc(projectRef);
                setProject(querySnapshot.data())
            }

            // const getUser = async () => {
            //     const querySnapshot = await getDoc(userRef);
            //     setUserData(querySnapshot.data())

            // }
            getUpdates().catch((error) => { console.error(error) })
            getProject().catch((error) => { console.error(error) })
            // getUser().catch((error) => { console.error(error) })
        }
    }, [])

    useEffect(() => {
        if (userData && project) {
            setPageTitle(userData.company_name + " - " + project.projectId)
        }
    }, [userData, project])


    return (<>
        <Helmet>
            <title>{pageTitle}</title>
            <meta property="og:title" content={pageTitle} />
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', padding:20 }}>
            <div style={{ width: 700, display: 'flex', gap: 20, flexDirection: 'column' }}>
                {/* <Header /> */}
                <ProjectInfo projectTitle={project?.projectId} customerRef={project?.customerRef}/>
                {updates?.map((update, index) => {
                    if (update?.status === "Published")
                        return (
                            <Update key={index} update={update} />
                        )
                })}
                <Footer userData={userData} />
            </div>
        </div>
    </>

    )
}

export default ProjectPage
