import React from 'react'
import { formatDate } from '../../utils/formating'
import Images from './Images'
import Video from './Video'
import styles from '../../styles/MainStyles.module.css'; // Import css modules stylesheet as styles


const Update = ({ update }) => {
    return (
        <div className={styles.dropShadowMain} style={{ backgroundColor: '#F5F5F5', borderRadius: 11, display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: 16, borderBottom: 'solid 1px white', padding: "10px 20px", color: "grey" }}>
                {formatDate(update?.date)}
            </div>
            <div style={{padding: "20px", gap: 20, display: 'flex', flexDirection: 'column' }}>
                {update?.widgets?.map((widget, index) => {

                    if (widget.type === "Heading")
                        return (
                            <div key={index}>
                                <h1>{widget.content}</h1>
                            </div>
                        )
                    if (widget.type === "Text")
                        return (
                            <div key={index}>
                                <p>{widget.content}</p>
                            </div>
                        )
                    if (widget?.type === "Images")
                        return (
                            <Images key={index} content={widget.content} />
                        )
                    if (widget.type === "Video")
                        return (
                            <Video key={index} content={widget.content} />
                        )
                })}
            </div>
        </div>
    )
}

export default Update