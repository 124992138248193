import React from 'react'
import styles from '../../styles/MainStyles.module.css'; // Import css modules stylesheet as styles


const ProjectInfo = ({ projectTitle, customerRef }) => {
    return (
        <div className={styles.dropShadowMain} style={{ backgroundColor: "#F5F5F5", borderRadius: 11, overflow: 'hidden' }}>
            <div style={{ padding: '10px 20px', backgroundColor: '#E3E3E3', fontWeight: 600 }}>
                Project Information:
            </div>
          
                <table cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td className={styles.tdkey}>
                                Title:
                            </td>
                            <td className={styles.tdval}>
                                {projectTitle}
                            </td>
                            <td className={styles.tdkey}>
                                Client:
                            </td>
                            <td className={styles.tdval}>
                                {customerRef}
                            </td>
                        </tr>
                    </tbody>
                </table>
        </div>
    )
}

export default ProjectInfo